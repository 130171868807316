import { tw } from '@/utils/tw';
import BaseButtonTheme from 'base/components/Button/theme';

const { Icon } = BaseButtonTheme;

const Button = tw.theme({
  extend: BaseButtonTheme,
  base: ['focus:ring-0', 'rounded-sm', 'border-0'],
  variants: {
    colors: {
      primary: ['bg-black', 'hover:bg-gray-800', 'focus:bg-gray-800', 'active:bg-error-600', 'text-white'],
      secondary: [
        'bg-linen-300',
        'hover:bg-linen-400',
        'focus:bg-linen-400',
        'active:bg-linen-400',
        'active:shadow-[inset_0px_0px_2px]',
        'active:shadow-black/25',
      ],
    },
    size: {
      small: ['px-5', 'py-1', 'text-content-sm', 'gap-1.5'],
      medium: ['px-6', 'py-2', 'text-content-sm', 'gap-1.5'],
      large: ['px-6', 'py-2.75', 'text-content-sm', 'gap-1.5'],
    },
  },
});

export default Object.assign(Button, { Icon });
