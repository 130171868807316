/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "accordion": {
    "extend": {
      "toggle_variant_default": "sm:hidden",
      "content_variant_default": "sm:block"
    }
  },
  "bar_group_colors_default": "bg-white xl:bg-transparent",
  "bar_group_size_default": "col-span-3 -mx-4 lg:group-data-[open]/menu:mx-0 lg:group-data-[open]/menu:max-w-screen-sm xl:col-span-1 xl:mx-0",
  "bar_group_variant_default": "relative overflow-x-auto overflow-y-hidden row-start-2 group-data-[open]/menu:overflow-x-visible group-data-[open]/menu:overflow-y-visible lg:group-data-[open]/menu:row-auto lg:group-data-[open]/menu:col-auto xl:row-auto xl:col-auto",
  "bar_colors_default": "bg-linen-100 wings-linen-100 lg:group-data-[open]/menu:bg-white lg:group-data-[open]/menu:wings-white",
  "bar_size_default": "px-4 pt-2 gap-2 gap-x-3 sm:gap-x-5 xl:py-3",
  "bar_variant_default": "relative wings grid grid-cols-[auto_auto_1fr] items-center overflow-x-auto lg:group-data-[open]/menu:grid-cols-[auto_auto_1fr_auto] xl:overflow-x-visible xl:grid-cols-[auto_auto_1fr_auto]",
  "drawer_toggle_animation_default": "",
  "drawer_toggle_animation_alternative": "",
  "drawer_toggle_colors_default": "",
  "drawer_toggle_size_default": "",
  "drawer_toggle_variant_default": "",
  "drawer_colors_default": "bg-linen-100 wings-linen-100 lg:group-data-[open]/menu:bg-white/80 lg:group-data-[open]/menu:wings-white/80",
  "drawer_size_default": "px-3 py-5 w-full h-[calc(100dvh_-_100%)] gap-y-5 sm:px-8 md:px-16 lg:px-25 sm:py-4 md:py-5 lg:py-6",
  "drawer_variant_default": "-translate-y-[120%] -z-2 absolute flex-col flex group-data-[open]:translate-y-0 left-0 overflow-y-auto overflow-x-hidden top-full transition-transform wings lg:overflow-visible backdrop-blur-sm",
  "logo_colors_default": "text-summer-red-500",
  "logo_colors_alternative": "",
  "logo_size_default": "flex-shrink-0 [&_svg]:h-full h-4.5 mx-0 sm:h-6 sm:mx-0 md:h-8",
  "logo_size_alternative": "",
  "logo_variant_default": "transition-all",
  "menu_group_colors_default": "",
  "menu_group_size_default": "md:gap-x-6",
  "menu_group_variant_default": "columns-1 overflow-y-auto sm:columns-2 md:grid md:grid-cols-3 lg:grid-cols-4",
  "menu_colors_primary": "",
  "menu_colors_secondary": "",
  "menu_colors_none": "",
  "menu_size_bar": "gap-x-1 px-0 lg:h-12 lg:gap-x-1.5",
  "menu_size_controls": "gap-x-2 sm:gap-x-3 lg:h-12",
  "menu_size_drawer": "gap-0 md:first-of-type:row-span-2 md:second-of-type:row-span-3",
  "menu_variant_bar": "flex group-data-[open]:hidden flex-row items-center overflow-x-auto overflow-y-hidden",
  "menu_variant_controls": "flex flex-row items-center justify-self-end",
  "menu_variant_drawer": "flex flex-col",
  "search_toggle_colors_default": "bg-primary-800 sm:bg-transparent text-white sm:text-primary-700",
  "search_toggle_size_default": "",
  "search_toggle_variant_default": "cursor-pointer relative",
  "search_colors_default": "bg-transparent",
  "search_size_bar": "w-full h-12 gap-x-2",
  "search_size_drawer": "w-full h-12 gap-x-2 max-w-screen-sm mx-auto",
  "search_variant_bar": "hidden flex-row items-center lg:group-data-[open]:flex",
  "search_variant_drawer": "flex flex-row items-center [&_button]:hidden sm:[&_button]:block lg:hidden",
  "soMe_group_colors_default": "",
  "soMe_group_size_default": "gap-3 my-6",
  "soMe_group_variant_default": "flex flex-wrap self-start md:col-start-3 md:row-start-2 md:row-span-2 lg:col-start-4 lg:row-span-1",
  "soMe_headline_colors_default": "text-gray-700",
  "soMe_headline_size_default": "w-full text-content-sm",
  "soMe_headline_variant_default": "uppercase",
  "soMe_colors_default": "",
  "soMe_size_default": "gap-3",
  "soMe_variant_default": "self-end flex flex-wrap",
  "textField_input_colors_default": "",
  "textField_input_size_default": "!w-full",
  "textField_input_size_large": "!w-full",
  "textField_input_variant_default": "",
  "textField_colors_default": "",
  "textField_size_default": "",
  "textField_variant_default": "flex-grow",
  "colors_default": "",
  "size_default": "w-full max-w-screen-xl mx-auto",
  "variant_default": "sticky top-0 left-0 z-menu group group/menu",
  "soMe_size_alternative": "gap-3",
  "soMe_variant_alternative": "self-end flex flex-wrap"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Navigation");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;