import { tw } from '@/utils/tw';
import BaseRecommendedArticlesTheme from 'base/components/RecommendedArticles/theme';

const Skeleton = tw.theme({
  extend: BaseRecommendedArticlesTheme.Skeleton,
  base: ['h-[350px]'],
});

const ArticleTeaser = tw.theme({
  extend: BaseRecommendedArticlesTheme.ArticleTeaser,
  slots: {
    description: ['line-clamp-2'],
  },
});

const RelatedArticles = tw.theme({
  extend: BaseRecommendedArticlesTheme,
  slots: {
    base: ['bg-linen-200', 'wings-linen-200', 'py-4', 'md:py-6', 'my-6', 'px-6'],
    headline: ['mb-2.5', 'md:mb-4.5', 'text-headline', 'sm:text-headline-lg'],
    list: ['gap-3', 'sm:gap-6'],
    listItem: ['mb-2', 'sm:mb-0', 'md:mb-6'],
  },
});

export default Object.assign(RelatedArticles, { ArticleTeaser, Skeleton });
