import { tw } from '@/utils/tw';
import BaseAuthorDetailsTheme from 'base/components/AuthorDetails/theme';

const { SoMe } = BaseAuthorDetailsTheme;

const Image = tw.theme({
  extend: BaseAuthorDetailsTheme.Image,
  variants: {
    variant: {
      default: {
        base: ['w-1/2', 'sm:w-45', 'data-[fill]:aspect-square'],
      },
    },
  },
});

const AuthorDetails = tw.theme({
  extend: BaseAuthorDetailsTheme,
  variants: {
    variant: {
      default: {
        base: ['bg-red', 'max-w-article-lg', 'gap-x-6', 'm-6', 'mb-0'],
        caption: ['text-preamble-sm'],
        description: ['text-preamble-sm', 'mt-1', 'mb-3', 'sm:mt-3'],
        email: ['text-summer-red-600', 'text-headline-sm', 'mt-2'],
        headline: ['text-headline', 'mb-0.5', 'sm:mb-1', 'mt-4', 'sm:mt-0'],
        soMeGroup: ['gap-x-3', 'mt-3', 'mb-3', 'sm:mb-1.5'],
      },
    },
  },
});

export default Object.assign(AuthorDetails, { Image, SoMe });
