import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseArticleHeaderTheme from 'base/components/ArticleHeader/theme';

const ContactUsLink = tw.theme({
  extend: BaseArticleHeaderTheme.ContactUsLink,
  slots: {
    base: `text-red-700`,
  },
});

const Tag = tw.theme({
  extend: TagTheme,
  variants: {
    colors: {
      text: 'text-summer-red-600',
    },
    variant: {
      text: 'inline-flex uppercase',
    },
  },
});

const ArticleHeader = tw.theme({
  extend: BaseArticleHeaderTheme,
  variants: {
    variant: {
      fourth: {
        category: `text-summer-red-600 order-4 sm:col-start-2`,
        description: `sm:text-preamble-lg text-preamble-sm`,
        headline: `order-2 col-span-full text-headline sm:col-start-1 sm:max-w-col-sm-9 sm:text-headline-2xl`,
        media: `order-3 col-span-2 sm:col-span-4`,
        tags: `order-1 col-span-2 flex sm:col-start-1 sm:max-w-article-lg`,
        date: `text-content-sm order-5 flex flex-col self-center text-gray-600 sm:col-span-2 sm:flex-row [&>*]:mb-[3px]`,
      },
    },
  },
  defaultVariants: {
    variant: 'fourth',
  },
});

export default Object.assign(ArticleHeader, { ContactUsLink, Tag });
